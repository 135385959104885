export default {
  home: {
    feiyong: '费用：',
    moshi: '代币模式',
    qianbao: '连接钱包',
    quancheng: '代币全称(比如：Tesla)',
    jiancheng: '代币简称(比如：Tsla)',
    jingdu: '代币精度(如果您不清楚这个意思，请填写：18)',
    zongliang: '供应总量(代币总数)',
    guanli: '管理员地址',
    yingxiao: '营销手续费',
    ranshao: '燃烧手续费(黑洞手续费)',
    yingxiaodizhi: '营销钱包地址',
    chuangjian: '创建代币',
    连接钱包: "连接钱包",
    biaozhun: '标准代币',
    shouxufei: '纯手续费代币',
    kaiyuan: '开源参数',
    yuandaima: '复制源代码',
    canshu: '复制构造参数',
    yagong: '鸭公社区',
    kafei: '请我喝杯咖啡',
    zhichi: '由鸭公社区提供技术支持',
  },
}