export default {
    home: {
        feiyong: 'Fee:',
        moshi: 'Token Mode',
        qianbao: 'ownadress',
        quancheng: 'The full name of the token (eg: Tesla)',
        jiancheng: 'Token abbreviation (for example: Tsla)',
        jingdu: 'Token precision (if you don’t know what this means, please fill in: 18)',
        zongliang: 'Total supply (total number of tokens)',
        guanli: 'Admin address',
        yingxiao: 'Marketing fee',
        ranshao: 'Burning fee (black hole fee)',
        yingxiaodizhi: 'Marketing wallet address',
        chuangjian: 'Create Token',
        连接钱包: "Connect Wallet",
        biaozhun: 'Standard Token',
        shouxufei: 'Pure Fee Token',
        kaiyuan: 'Open source parameters',
        yuandaima: 'Copy code',
        canshu: 'Copy constructor parameters',
        yagong: 'Duck Community',
        kafei: 'Buy me a cup of coffee',
        zhichi: 'Technical support provided by Yagong Community',
    },
}